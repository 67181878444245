.cb2{.ant-modal-body{
    padding: 0!important;
    border-radius :8px;

}
.title-cb2{
    background:#01638D;
    color:#fff;
    width: 100%;
}
.ant-modal-confirm-btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    padding-top: 0px;
}}
