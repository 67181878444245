.ant-modal .ant-modal-header {
  // background-color: var(--color-primary);
  color: #fff;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 10px;
  // padding-left: 10px;
}
.ant-modal-close {
  top: 12px !important;
}
// .ant-modal-close-icon {
//   svg path {
//     fill: #fff;
//   }
// }
.ant-modal-header .ant-modal-title {
  color: #154398;
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}
// .ant-modal-footer {
//   position: relative;
//   padding: 0 0 16px 0;
//   margin: 0px 16px 0px 16px;
// }
.ant-modal-header:after {
  background-image: -webkit-linear-gradient(0deg, #154398, #ee1d23);
  bottom: -2px;
  content: "";
  height: 4px;
  left: 40px;
  margin: 0 0 0 -40px;
  position: absolute;
  width: 20%;
}
// lable input form
.ant-form-item-label label {
  font-weight: 600;
}
